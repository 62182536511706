import { DestroyRef, Injectable, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IdentityService, Myself } from '@finboot/ng-identity-management';
import {
    Observable,
    ReplaySubject,
    catchError,
    filter,
    of,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs';
import { OauthService } from './oauth.service';

@Injectable({
    providedIn: 'root',
})
export class IdentityServiceWrapper {
    private identityService = inject(IdentityService);
    private oauthService = inject(OauthService);
    private destroyRef = inject(DestroyRef);

    // Cache control
    private cacheInvalidated = signal(true);
    private myselfCache$ = new ReplaySubject<Myself>(1);

    // Public observable for myself data with caching
    readonly myself$: Observable<Myself> = this.oauthService.isAuthenticated$.pipe(
        // Only proceed when authenticated
        filter((isAuthenticated) => isAuthenticated),
        // When authentication state changes, invalidate cache
        tap(() => this.cacheInvalidated.set(true)),
        // Switch to the cached stream or fetch fresh data
        switchMap(() => {
            if (this.cacheInvalidated()) {
                return this.fetchAndCacheMyself();
            }
            return this.myselfCache$.asObservable();
        }),
        // Share the result across subscribers
        shareReplay(1),
        // Clean up when component is destroyed
        takeUntilDestroyed(this.destroyRef),
    );

    /**
     * Gets the current user identity
     * @returns Observable of Myself data
     */
    getMyself(): Observable<Myself> {
        return this.myself$;
    }

    /**
     * Force refresh the myself data
     * @returns Observable of refreshed Myself data
     */
    refreshMyself(): Observable<Myself> {
        this.cacheInvalidated.set(true);
        return this.fetchAndCacheMyself();
    }

    /**
     * Internal method to fetch and cache user data
     */
    private fetchAndCacheMyself(): Observable<Myself> {
        return this.identityService.getMyIdentity().pipe(
            tap((myself: Myself) => {
                this.myselfCache$.next(myself);
                this.cacheInvalidated.set(false);
            }),
            catchError((error) => {
                console.error('Failed to fetch identity:', error);
                // If the error is authentication-related, let OauthService handle it
                if (error.status === 401 || error.status === 403) {
                    this.oauthService.ensureAuthenticated().catch((err) => {
                        console.error('Authentication failed:', err);
                    });
                }
                return of({} as Myself); // Return empty object to prevent breaking the stream
            }),
        );
    }
}
